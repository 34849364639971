<template>
    <!-- <nav class="navbar navbar-expand-sm navbar-light bg-light fixed-top px-0 py-0 justify-content-center"> -->
    <nav class="navbar navbar-expand-sm navbar-light fixed-top px-0 py-0 justify-content-center">
        <div class="container-fluid">
            <div class="navbar-brand">
                <div v-if="validSession === true" @click="isMenuOpen = !isMenuOpen">
                    <div v-if="desc">
                        <table border="0">
                            <tr>
                                <td rowspan="2" class="py-0">
                                    <div class="d-sm-none" style="width: 140px">
                                        <img alt="SignOn logo" src="../assets/signon_logo10.1a.png" class="align-top isLink" style="height: 32px" id="SignOnLogo" />
                                        <!-- <span v-if="isSignOn === true && showRefresh === true" class="align-middle mt-3" style="font-size: 14px">
                                            <Popper class="popperDark" arrow hover content="A new update is available! Please refresh the page.">
                                                <fa icon="circle-info" class="text-warning" style="font-size: 22px" />
                                            </Popper>
                                        </span> -->
                                    </div>
                                    <div class="d-none d-sm-block" style="width: 243px">
                                        <img alt="SignOn logo" src="../assets/signon_logo10.1a.png" class="align-top isLink" style="height: 32px" id="SignOnLogo" />
                                        <!-- <span v-if="isSignOn === true && showRefresh === true" class="mx-2 align-middle pt-1" style="font-size: 14px">
                                            <Popper class="popperDark" arrow hover content="A new update is available! Please refresh the page.">
                                                <fa icon="circle-info" class="text-warning" style="font-size: 25px" />
                                            </Popper>
                                        </span> -->
                                    </div>
                                    
                                </td>
                                <td id="lblNavDesc">
                                    <span v-if="desc">
                                        <span v-if="desc && desc.length > 15" class="d-inline d-sm-none ps-4">
                                            <Popper class="popperDark" arrow hover :content="desc">
                                                {{desc.substring(0, 15) }}...
                                            </Popper>
                                        </span>
                                        <span v-else class="d-inline d-sm-none ps-4">{{ desc }}</span>
                                        <span v-if="desc.length > 28" class="d-none d-sm-inline d-md-none">
                                            <Popper class="popperDark" arrow hover :content="desc">
                                                {{desc.substring(0, 28) }}...
                                            </Popper>
                                        </span>
                                        <span v-else class="d-none d-sm-inline d-md-none">{{ desc }}</span>
                                        <span v-if="desc.length > 55" class="d-none d-md-inline d-lg-none">
                                            <Popper class="popperDark" arrow hover :content="desc">
                                                {{desc.substring(0, 55) }}...
                                            </Popper>
                                        </span>
                                        <span v-else class="d-none d-md-inline d-lg-none">{{ desc }}</span>
                                        <span v-if="desc.length > 85" class="d-none d-lg-inline d-xl-none">
                                            <Popper class="popperDark" arrow hover :content="desc">
                                                {{desc.substring(0, 85) }}...
                                            </Popper>
                                        </span>
                                        <span v-else class="d-none d-lg-inline d-xl-none">{{ desc }}</span>
                                        <span v-if="desc.length > 112" class="d-none d-xl-inline d-xxl-none">
                                            <Popper class="popperDark" arrow hover :content="desc">
                                                {{desc.substring(0, 112) }}...
                                            </Popper>
                                        </span>
                                        <span v-else class="d-none d-xl-inline d-xxl-none">{{ desc }}</span>
                                        <span v-if="desc.length > 142" class="d-none d-xxl-inline">
                                            <Popper class="popperDark" arrow hover :content="desc">
                                                {{desc.substring(0, 142) }}...
                                            </Popper>
                                        </span>
                                        <span v-else class="d-none d-xxl-inline">{{ desc }}</span>
                                    </span>
                                </td>
                            </tr>
                            <tr id="lblNavSubDesc">
                                <td>
                                    <span v-if="descEntity">{{ descEntity }}</span>
                                    <span v-if="descEntity && descOwner">
                                        <fa icon="circle" class="px-2" style="font-size: 3px; padding-bottom: 1px" />
                                    </span>
                                    <span v-if="descOwner">{{ descOwner }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else style="padding-top: 3px; padding-bottom: 4px">
                        <img alt="SignOn logo" src="../assets/signon_logo10.1a.png" class="align-top isLink" style="height: 32px" id="SignOnLogo" />
                        <!-- <span v-if="isSignOn === true && showRefresh === true" class="mx-2 mt-0" style="font-size: 14px">
                            <Popper class="popperDark" arrow hover content="A new update is available! Please refresh the page.">
                                <fa icon="circle-info" class="text-warning" style="font-size: 25px" /> 
                            </Popper>
                        </span> -->
                    </div>
                    
                </div>
                <div v-else>
                    <a href="https://signon.my">
                        <img alt="SignOn logo" src="../assets/signon_logo10.1a.png" class="align-top" style="height: 32px" />
                    </a>
                </div>

                <!-- menu start -->
                <div v-if="validSession === true && isMenuOpen === true" class="rounded-0 navbar-light-content" id="loginMenu" ref="loginMenu">
                    <div class="row py-3 px-4">
                        <div class="col-md-4 pb-4" v-for="(menus, menuCat) in arrMenu" :key="menuCat">
                            <div class="row">
                                <div class="col-12 navbar-light-title" style="padding-left: 28px;">{{menuCat}}</div>
                                <div class="col-12 d-flex flex-wrap" style="max-width: 420px">
                                    <div class="menuBox" v-for="m in menus" :key="m">
                                       <router-link :to="m.path">
                                            <fa v-if="m.menu === 'Dashboard'" icon="gauge" class="menu-icon" />
                                            <fa v-if="m.menu === 'Folder'" icon="folder" class="menu-icon" />
                                            <fa v-if="m.menu === 'Contact'" icon="user-circle" class="menu-icon" />
                                            <fa v-if="m.menu === 'Team'" icon="users" class="menu-icon" />
                                            <fa v-if="m.menu === 'API'" icon="diagram-project" class="menu-icon" />
                                            <fa v-if="m.menu === 'Verify Doc'" icon="check-double" class="menu-icon" />
                                            <fa v-if="m.menu === 'Form'" icon="file-invoice" class="menu-icon" />
                                            <fa v-if="m.menu === 'Workflow'" icon="sitemap" class="menu-icon" />
                                            <fa v-if="m.menu === 'Configuration'" icon="screwdriver-wrench" class="menu-icon" />
                                            <fa v-if="m.menu === 'Subscriber'" icon="cart-plus" class="menu-icon" />
                                            <fa v-if="m.menu === 'KYC Verification'" icon="address-card" class="menu-icon" />
                                            <fa v-if="m.menu === 'Admin Report'" icon="chart-line" class="menu-icon" />
                                            <fa v-if="m.menu === 'Transaction'" icon="bars-staggered" class="menu-icon" />
                                            <div class="menu-text">{{m.menu}}</div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- menu end -->

                
            </div>
            
            <!-- <div v-if="validSession === true">
                <span v-if="getUserStatus !== 'ACTIVE'" class="me-2">
                    <Popper class="popperDark" arrow hover :content="'Inactive user - ' + userExpired + ' left for account activation'">
                        <fa icon="hourglass-half" class="navbar-display-name isLink" style="font-size: 18px" data-bs-toggle="modal" data-bs-target="#mdlActivateUser" />
                    </Popper>
                </span>
            </div> -->

            <!-- Small screen: center 
            <div class="d-md-none">
                <span v-if="getTheme === 'dark-theme'" @click="setTheme('light-theme')">
                    <fa icon="moon" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                </span>
                <span v-else @click="setTheme('dark-theme')" class="me-2">
                    <fa icon="sun" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                </span>
                <span>
                    <select v-model="$i18n.locale" @change="setLang($i18n.locale)" class="me-2">
                        <option value="en">EN</option>
                        <option value="bm">BM</option>
                        <option value="ch">CH</option>
                    </select>
                </span>
            </div> -->
            <button class="navbar-toggler align-middle py-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-labelledby="collapsibleNavbar">
                <span v-if="validSession === true" class="my-0">
                    <table>
                        <tr>
                            <!-- <td v-if="getUserStatus !== 'ACTIVE'" class="pt-0 mt-0"><button class="btn btn-info btn-sm text-white" style="font-size: 9px" type="button">Activate<br>account</button></td> -->
                            <td v-if="getUserStatus !== 'ACTIVE'" class="pt-0 mt-0 pe-2">
                                <button class="btn btn-info text-white btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#mdlActivationGuide"><fa icon="hand-pointer" class="me-2" />Activation</button>
                            </td>
                            <!-- <td><Avatar :isUser="true" :avatar="getAvatar" displayName="" :isVerified="getUserIsVerified" :isActive="getUserStatus" class="fw-bold navbar-display-name"/></td> -->
                            <td><ImgAvatar :isUser="true" :id="getUserId" displayName="" :isVerified="getUserIsVerified" :isActive="getUserStatus" class="fw-bold navbar-display-name" align="left" /></td>
                        </tr>
                    </table>
                </span>
                <span v-else class="navbar-toggler-icon"></span>
            </button>

            <div v-if="validSession === false" class="collapse navbar-collapse" id="collapsibleNavbar">
                <ul v-if="hideLogin !== true" class="navbar-nav ms-auto d-md-none">
                    <li class="nav-item px-1 py-2" @click="$router.push({ path: '/auth/login' })">
                        <span class="greyLink">Login</span>
                    </li>
                    <li class="nav-item px-1 py-2">
                        <span v-if="getTheme === 'dark-theme'" class="greyText">Dark theme</span>
                        <span v-else class="greyText">Light theme</span>
                        <span v-if="getTheme === 'dark-theme'" class="float-end" @click="setTheme('light-theme')">
                            <fa icon="moon" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                        </span>
                        <span v-else class="float-end" @click="setTheme('dark-theme')">
                            <fa icon="sun" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                        </span>
                    </li>
                    <li class="nav-item px-1 py-2" @click="$router.push({ path: '/doc/verify' })">
                        <span class="greyLink">Verify doc</span>
                    </li>
                    <!-- Enerson: temp hide
                    <li class="nav-item px-1 py-2">
                        <span class="greyText align-middle">Language</span>
                        <span class="float-end" style="width: 100px">
                            <select v-model="$i18n.locale" @change="setLang($i18n.locale)" class="form-select form-select-sm">
                                <option value="en">English</option>
                                <option value="bm">Malay</option>
                                <option value="ch">Chinese</option>
                            </select>
                        </span>
                    </li> -->
                </ul>
            </div>

            <span v-if="validSession === false" class="d-none d-md-block float-end">
                <table border="0">
                    <tbody>
                        <tr>
                            <td class="pe-3">
                                <span class="isLink" @click="$router.push({ path: '/auth/login' })" >
                                    <Popper class="popperDark" arrow hover content="Login">
                                        <fa icon="circle-user" class="navbar-display-name" style="font-size: 20px"/>
                                    </Popper>
                                </span>
                            </td>
                            <td class="pe-3">
                                <span v-if="getTheme === 'dark-theme'" @click="setTheme('light-theme')">
                                    <Popper class="popperDark" arrow hover content="Dark mode">
                                        <fa icon="moon" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                                    </Popper>
                                </span>
                                <span v-else @click="setTheme('dark-theme')">
                                    <Popper class="popperDark" arrow hover content="Light mode">
                                        <fa icon="sun" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                                    </Popper>
                                </span>
                            </td>
                            <td>
                                <ul class="navbar-nav" id="menuLang">
                                    <li class="nav-item dropdown align-start">

                                        <!-- Enerson: temp hide language
                                        <a class="nav-link py-0 px-0" href="#" role="button" data-bs-toggle="dropdown">
                                            <Popper class="popperDark" arrow hover content="Language">
                                                <fa icon="globe" style="font-size: 20px; padding-top: 5px; padding-bottom: 5px" />
                                            </Popper>
                                        </a> -->
                                        <button type="button" class="btn btn-info btn-sm text-white" @click="$router.push({ path: '/doc/verify' })">
                                            Verify Doc
                                        </button>
                                        <ul id="menuLang" class="dropdown-menu dropdown-menu-end dropdown-menu-profile">
                                            <li>
                                                <a class="dropdown-item" href="#" @click="$i18n.locale = 'en'; setLang('en')">
                                                    English <span v-if="$i18n.locale === 'en'" class="float-end"><fa icon="check" /></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" href="#" @click="$i18n.locale = 'bm'; setLang('bm')">
                                                    Malay <span v-if="$i18n.locale === 'bm'" class="float-end"><fa icon="check" /></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" href="#" @click="$i18n.locale = 'ch'; setLang('ch')">
                                                    Chinese <span v-if="$i18n.locale === 'ch'" class="float-end"><fa icon="check" /></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <span>
                    <span class="isLink me-3" @click="$router.push({ path: '/auth/login' })" >
                        <Popper class="popperDark" arrow hover content="Login">
                            <fa icon="circle-user" class="navbar-display-name" style="font-size: 20px"/>
                        </Popper>
                    </span>
                </span>
                <span v-if="getTheme === 'dark-theme'" @click="setTheme('light-theme')">
                    <Popper class="popperDark" arrow hover content="Dark mode"><fa icon="moon" style="font-size: 20px; cursor: pointer" class="navbar-display-name" /></Popper>
                </span>
                <span v-else @click="setTheme('dark-theme')">
                    <Popper class="popperDark" arrow hover content="Light mode"><fa icon="sun" style="font-size: 20px; cursor: pointer" class="navbar-display-name" /></Popper>
                </span> -->
                <!-- <span>
                    <select v-model="$i18n.locale" @change="setLang($i18n.locale)" class="form-select form-select-sm">
                        <option value="en">English</option>
                        <option value="bm">Malay</option>
                        <option value="ch">Chinese</option>
                    </select>
                </span> -->
                
            </span>
            
            <div v-if="validSession === true" class="collapse navbar-collapse" id="collapsibleNavbar">
                <!-- Large screen -->
                <ul class="navbar-nav ms-auto d-none d-sm-block" id="topRightMenu">
                    <li class="nav-item dropdown align-middle" >
                        <a class="nav-link py-0" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="false">
                            <div style="height: 35px">
                                <table>
                                    <tr>
                                        <td v-if="getUserStatus !== 'ACTIVE'" class="pt-0 mt-0 pe-2">
                                            <button class="btn btn-info text-white" type="button" data-bs-toggle="modal" data-bs-target="#mdlActivationGuide"><fa icon="hand-pointer" class="me-2" />Activation</button>
                                        </td>
                                        <!-- <td><Avatar :avatar="getAvatar" :key="Math.random()" align="right" :isUser="true"  displayName="" :isVerified="getUserIsVerified" :isActive="getUserStatus" width="35" /></td> -->
                                        <td><ImgAvatar :id="getUserId" :key="Math.random()" align="left" :isUser="true"  displayName="" :isVerified="getUserIsVerified" :isActive="getUserStatus" width="35" /></td>
                                    </tr>
                                </table>
                            </div>
                        </a>
                        
                        <ul id="topNameMenu" class="dropdown-menu dropdown-menu-end dropdown-menu-profile" style="font-size: 14px" >
                            <li class="px-3 py-2">
                                <table border="0" width="250px">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="text-secondary">{{getDisplayName}}</div>
                                                <div class="text-grey small">{{getUserId}}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                            <li @click="$router.push({path: '/auth/preference'})"><span class="dropdown-item greyLink">Preferences</span></li>
                            <li><hr class="dropdown-divider"></li>
                            <li v-if="isSignOn === true" @click="$router.push({path: '/auth/referral'})"><span class="dropdown-item greyLink bg-warning">Referrals</span></li>
                            <li v-if="isSignOn === true"><hr class="dropdown-divider"></li>
                            <li v-if="getUserStatus !== 'ACTIVE'" class="dropdown-item py-2">
                                <div>
                                    <Popper class="popperDark" arrow hover :content="'Inactive user - ' + userExpired + ' left for account activation'">
                                        <fa icon="hourglass-half" class="navbar-display-name isLink me-2" style="font-size: 18px" data-bs-toggle="modal" data-bs-target="#mdlResendActivation" @click="getCaptchaResend" />
                                    </Popper>
                                    <span style="color: var(--text-grey-link)">Email validation required</span>
                                </div>
                                <div class="pt-2 text-grey pb-2">Please activate your email <br />{{ getUserId }} from your INBOX.</div>
                                <div class="text-grey">If you do not receive the activation<br /> email, please request again <span class="isLink text-info" data-bs-toggle="modal" data-bs-target="#mdlResendActivation" @click="getCaptchaResend">here</span>.</div>
                                
                            </li>
                            <li v-if="getUserStatus !== 'ACTIVE'" ><hr class="dropdown-divider"></li>
                            <li class="dropdown-item py-2">
                                <span v-if="getTheme === 'dark-theme'" class="greyText">Dark mode</span>
                                <span v-else class="greyText">Light mode</span>
                                <span v-if="getTheme === 'dark-theme'" @click="setTheme('light-theme')" class="float-end">
                                    <fa icon="moon" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                                </span>
                                <span v-else @click="setTheme('dark-theme')" class="float-end">
                                    <fa icon="sun" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                                </span>
                            </li>
                            <!-- Enerson: temp hide language
                            <li class="dropdown-item py-0">
                                <table border="0" width="100%">
                                    <tbody>
                                        <tr>
                                            <td class="greyText">Language</td>
                                            <td class="text-end">
                                                <select v-model="$i18n.locale" @change="setLang($i18n.locale)" class="form-select form-select-sm">
                                                    <option value="en">English</option>
                                                    <option value="bm">Malay</option>
                                                    <option value="ch">Chinese</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li> -->
                            <li><hr class="dropdown-divider"></li>
                            <li @click="logout"><span class="dropdown-item greyLink">Logout</span></li>
                        </ul>
                    </li>
                </ul>
                
                <!-- Small screen -->
                <ul class="navbar-nav ms-auto d-sm-none">
                    <!-- <li class="nav-item dropdown align-middle">
                        <router-link to="/auth/preference"><span class="dropdown-item greyLink px-0">Preferences</span></router-link>
                        <a class="nav-link" href="#" @click="logout"><span class="greyLink">Logout...</span></a>
                    </li> -->
                    <li class="nav-item dropdown align-middle dropdown-item pt-3 pb-0">
                        <table class="table table-sm table-borderless" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        <div>{{getDisplayName}}</div>
                                        <div class="text-grey small">{{getUserId}}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li><hr class="dropdown-divider"></li>
                    <li class="nav-item dropdown align-middle dropdown-item py-3">
                        <router-link to="/auth/preference">Preferences</router-link>
                    </li>
                    <li v-if="getUserStatus !== 'ACTIVE'" class="nav-item dropdown align-middle dropdown-item py-3" >
                        <div>
                            <Popper class="popperDark" arrow hover :content="'Inactive user - ' + userExpired + ' left for account activation'">
                                <fa icon="hourglass-half" class="navbar-display-name isLink me-2" style="font-size: 18px" data-bs-toggle="modal" data-bs-target="#mdlResendActivation" @click="getCaptchaResend" />
                            </Popper>
                            <span style="color: var(--text-grey-link)">Email validation required</span>
                        </div>
                        <div class="pt-2">
                            <div class="text-grey pb-2">Please activate your email<br /> {{ getUserId }} from your INBOX.</div>
                            <div class="text-grey">If you do not receive the activation<br /> email, please request again <span class="isLink text-info" data-bs-toggle="modal" data-bs-target="#mdlResendActivation" @click="getCaptchaResend">here</span>.</div>
                        </div>
                        
                    </li>
                    <li class="nav-item dropdown align-middle dropdown-item py-3">
                        <span v-if="getTheme === 'dark-theme'" class="greyText">Dark theme</span>
                        <span v-else class="greyText">Light theme</span>
                        <span v-if="getTheme === 'dark-theme'" class="float-end" @click="setTheme('light-theme')">
                            <fa icon="moon" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                        </span>
                        <span v-else class="float-end" @click="setTheme('dark-theme')">
                            <fa icon="sun" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                        </span>
                    </li>
                    <li class="nav-item dropdown align-middle dropdown-item py-3">
                        <a href="#" @click="logout">Logout</a>
                    </li>

                    <!-- Enerson: temp hide
                    <li class="nav-item dropdown align-middle dropdown-item py-2">
                        <span class="greyText align-middle">Language</span>
                        <span class="float-end" style="width: 100px">
                            <select v-model="$i18n.locale" @change="setLang($i18n.locale)" class="form-select form-select-sm">
                                <option value="en">English</option>
                                <option value="bm">Malay</option>
                                <option value="ch">Chinese</option>
                            </select>
                        </span>
                    </li> -->
                   
                </ul>

            </div>
           
        </div>
    </nav>

    <nav v-if="!hideSecondNavbar" class="navbar navbar-expand-sm justify-content-center fixed-top py-2"
        style="margin-top: 41px; z-index: 1">
        <div class="navbar-nav">

            <div v-if="title === 'Document Preview'" class="fw-bold" style="font-size: 20px; padding-top: 1px;">
                <span><fa :icon="['fab', 'searchengin']" class="me-2"/></span>
                {{ title }}
            </div>
            <div v-else-if="title && title !== 'Workflow Step' && icon !== 'bars'" class="fw-bold" style="font-size: 20px; padding-top: 1px;">
                <span v-if="icon"><fa :icon="icon" class="me-2"/></span>
                {{ title }}
            </div>
            
            <div v-if="title === 'Workflow Step' || route.params.object == 'signatory'" class="p-0 m-0">
                <table border="0" class="m-0 p-0">
                    <tr>
                        <td v-if="step === '1'">
                            <Step number="1" desc="Step" descClass="text-info" />
                        </td>
                        <td v-else-if="step === '2' || step === '3'">
                            <router-link to="/workflow/add"><Step number="1" desc="Step" /></router-link>
                        </td>
                        <td v-else>
                            <router-link to="/workflow/add"><Step number="1" desc="Step" disabled="true" /></router-link>
                        </td>
                        <td><fa icon="caret-right" size="lg" class="me-1"/></td>

                        <td v-if="step === '2'"><Step number="2" desc="Document" descClass="text-info" /></td>
                        <td v-else-if="step === '3'"><router-link to="/workflow/doc"><Step number="2" desc="Document" /></router-link></td>
                        <td v-else><router-link to="/workflow/doc"><Step number="2" desc="Document" disabled="true" /></router-link></td>
                        <td><fa icon="caret-right" size="lg" class="me-1"/></td>

                        <td v-if="step === '3'"><Step number="3" desc="Object" descClass="text-info" /></td>
                        <td v-else><router-link to="/workflow/signatory"><Step number="3" desc="Object" disabled="true" /></router-link></td>
                    </tr>
                </table>
            </div>

            <!-- large screen -->
            <div v-else-if="!title" class="d-none d-md-block py-1">
                <span :class="step === '1' ? 'selectedMenu' : ''">
                    <span v-if="step === '1'"><fa icon="upload" size="lg" class="me-1"/> Upload</span>
                    <router-link v-else :to="lnkUploadDoc"><fa icon="upload" size="lg" class="me-1"/> Upload</router-link>
                </span>
                <span class="px-4"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '2' ? 'selectedMenu' : ''">
                    <span v-if="disabledSignee"><fa icon="user-circle" size="lg" class="me-1"/> Signee</span>
                    <router-link v-else to="/addSignee"><fa icon="user-circle" size="lg" class="me-1"/> Signee</router-link>
                </span>
                <span class="px-4"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '3' ? 'selectedMenu' : ''">
                    <span v-if="disabledSignatory"><fa icon="file-signature" size="lg" class="me-1"/> Signatory</span>
                    <router-link v-else to="/signatory"><fa icon="file-signature" size="lg" class="me-1"/> Signatory</router-link>
                    <!-- <router-link v-else :to="lnkSignatory"><fa icon="file-signature" size="lg" class="me-1"/> Signatory</router-link> -->
                </span>
                <span class="px-4"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '4' ? 'selectedMenu' : ''">
                    <span v-if="disabledReview"><fa icon="envelope-open" size="lg" class="me-1"/> Review</span>
                    <router-link v-else to="/reviewInvite"><fa icon="envelope-open" size="lg" class="me-1"/> Review</router-link>
                </span>
            </div>

            <!-- small screen -->
            <div v-if="!title" class="d-block d-md-none py-1">
                <span :class="step === '1' ? 'selectedMenu' : ''">
                    <span v-if="step === '1'" class="text-primary">Upload</span>
                    <span v-else>Upload</span>
                </span>
                <span class="px-3"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '2' ? 'selectedMenu' : ''">
                    <span v-if="disabledSignee">Signee</span>
                    <router-link v-else to="/addSignee">Signee</router-link>
                </span>
                <span class="px-3"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '3' ? 'selectedMenu' : ''">
                    <span v-if="disabledSignatory">Signatory</span>
                    <router-link v-else to="/signatory">Signatory</router-link>
                    <!-- <router-link v-else :to="lnkSignatory">Signatory</router-link> -->
                </span>
                <span class="px-3"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '4' ? 'selectedMenu' : ''">
                    <span v-if="disabledReview">Review</span>
                    <router-link v-else to="/reviewInvite">Review</router-link>
                </span>
            </div>

        </div><!-- navbar-nav end-->
    </nav>

    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>

    <nav class="navbar navbar-expand-sm navbar-light fixed-bottom">
        <div style="width: 100%; text-align: center">Copyright © 2023 SignOn. All rights reserved.</div>
    </nav>

    <!-- Modal: Activation guide--> 
    <div class="modal fade" id="mdlActivationGuide">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Activate My Account</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table border="0" class="mb-4" align="center">
                        <tr>
                            <td colspan="3" class="text-center px-3">
                                <img alt="ActivationStep" src="../assets/s1.png" class="img-fluid">
                            </td>
                        </tr>
                        <tr class="text-center">
                            <td width="33%">Registered with email address</td>
                            <td width="*">Activate your<br />account</td>
                            <td width="33%">Sign documents<br />online</td>
                        </tr>
                    </table>

                    <ol>
                        <li class="mb-3">
                            Check your mailbox {{ getUserId }} for activation email from SignOn.my
                            <span class="small text-grey"><i>Some email clients may accidentally classify the email as Junk or Spam</i></span>
                        </li>
                        <li>
                            Click on the <span class="mx-2 py-2 px-3 bg-info text-gray" style="border-radius: 20px">Activate My Account</span> button in the email
                            <div class="mt-3">
                                <img alt="ActivationEmail" src="../assets/activation_email.png" class="img-fluid">
                            </div>
                        </li>
                    </ol>

                    <div class="pt-2 ps-3">Click <span class="isLink text-info" data-bs-toggle="modal" data-bs-target="#mdlResendActivation" @click="getCaptchaResend">here</span> to request for new activation email if you do not receive the email, or contact our support <a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <!-- <button type="button" class="btn btn-secondary" @click="resendActivation">
                        <fa icon="envelope" size="lg" class="me-2" />Resend
                    </button> -->
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Resend Activation--> 
    <div class="modal fade" id="mdlResendActivation">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Resend Activation Email</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 pb-2">
                            Fill in detail below to resend activation link
                        </div>

                        <div class="col-12 pb-2">
                            <input v-model="resendEmail" class="form-control" placeholder="Email">
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div style="width: 200px; height: 100px">
                                <img :src="captchaImgResend" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptchaResend" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                    <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                        <fa icon="refresh" />
                                    </Popper>
                                </button>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="captchaResend" class="form-control" placeholder="Captcha">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button type="button" class="btn btn-secondary" @click="resendActivation">
                        <fa icon="envelope" size="lg" class="me-2" />Resend
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Account activation --> 
    <!-- <div class="modal fade" id="mdlActivateUser" tabindex="-1" aria-labelledby="AddNote" aria-hidden="true"> -->
    <!-- <div class="modal fade" id="mdlActivateUser" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Account activation</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            Your account is registered but not activated, please activate your account.
                        </div>
                        <div class="col-12">
                            If you do not receive activation email, please click 
                            <span class="isLink" @click="resendActivation"><u>here</u></span> 
                            to send again, wait 5 minutes and check your junk/spam folder.
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Modal End-->

</template>

<script>
import { ref, computed, inject, onMounted } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import auth from '@/functions/auth'
import Popper from 'vue3-popper'
import ImgAvatar from '@/components/ImgAvatar.vue'
import funcs from '@/functions/function'
import Step from '@/components/StepWorkflow.vue'

export default {
    props: [ 'title', 'icon', 'step', 'disabledAll', 'disabledSignee', 'disabledSignatory', 'disabledReview', 'hideLogin', 'hideSecondNavbar', 'desc', 'descOwner', 'descEntity' ],
    components: { Alert, Popper, ImgAvatar, Step },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const authFunc = auth
        const store = useStore()
        const { getDisplayName, getAvatar, getTheme, getUserIsVerified, getUserStatus, getUserId, getUserExpired, getPackage, getStructure, updUserIsVerified } = useStore() // reflect immediately

        const isSignOn = ref(false)
        const sessionId = ref(null)
        const hasSUPerm = ref(false)
        const lnkUploadDoc = ref('/uploadDoc')
        const lnkSignatory = ref('/pdf/detail')

        const arrMenu = ref([])
        const theme = ref('light-theme')
        const userDisplayName = ref(null)
        const userExpired = ref(null)
        const userStatus = ref(null)
        const userPackage = ref(null)   // Plan
        const userStructure = ref(null) // PERSONAL/TEAM
        const userIsVerified = ref(false)
        const userId = ref('')
        const validSession = ref(false)

        const email = ref(null)
        const displayName = ref(null)
        const captchaImgResend = ref(null)
        const refIdResend = ref(null)
        const captchaResend = ref(null)
        const resendEmail = ref(null)

        const isMenuOpen = ref(false)
        const loginMenu = ref(null)
        const projectId = ref(null)
        const objLSProj = ref({})

        const showRefresh = ref(false)
        const version = ref('')

        sessionId.value = store.getSessionId.value


        const logout = async () => {
            const isLogout = await authFunc.logout()

            if (isLogout[0] === '1') {
                //ORIG: router.push('/auth/login')
                router.push({ name: 'Login', query: { m: '1' } })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })
            } else {

                if (isLogout[1] === 'no_session') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. Session not found.'
                    })

                } else if (isLogout[1] === 'user_not_online') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. User not online.'
                    })

                } else {

                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. ' + isLogout[1]
                    })
                }

                // auto redirect to login page when logout failed
                // router.push('/auth/login')
                router.push({ name: 'Login' })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })
            }
        } 

        const setTheme = async (theme1) => {
            theme.value = computed({
                get: () => store.getTheme.value,
                set: store.updTheme(theme1),
            })
            theme.value = store.getTheme.value

            document.documentElement.className = theme1
        }

        const setLang = (lang) => {
            localStorage.setItem('lang', lang)
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const chkSession = async () => {
            validSession.value = false
            
            try {
                const res = await axios.get('/auth/session')

                if( res.data.status === 1) {

                    validSession.value = true
                    arrMenu.value = res.data.data.jMenu

                    userStatus.value = computed({
                        get: () => store.getUserStatus.value,
                        set: store.updUserStatus(res.data.data.status),
                    })
                    userStatus.value = store.getUserStatus.value 

                    userIsVerified.value = computed({
                        get: () => store.getUserIsVerified.value,
                        set: store.updUserIsVerified(res.data.data.isVerified),
                    })
                    userIsVerified.value = store.getUserIsVerified.value 

                    userPackage.value = computed({
                        get: () => store.getPackage.value,
                        set: store.updPackage(res.data.data.plan),
                    })
                    userPackage.value = store.getPackage.value 

                    userStructure.value = computed({
                        get: () => store.getStructure.value,
                        set: store.updStructure(res.data.data.structure),
                    })
                    userStructure.value = store.getStructure.value 

                    userDisplayName.value = computed({
                        get: () => store.getDisplayName.value,
                        set: store.updDisplayName(res.data.data.displayName),
                    })
                    userDisplayName.value = store.getDisplayName.value 

                    if(res.data.data.jTeam && res.data.data.jTeam.length > 0) {
                        for (var i = 0; i < res.data.data.jTeam.length; i++) {
                            if( res.data.data.jTeam[i].id === '2' && res.data.data.jEntity.entityId === '1') {
                                hasSUPerm.value = true

                            }
                        }
                    }
                   
                } else {
                    validSession.value = false
                    authFunc.clearStorage()

                    if (res && res.data !== null && res.data !== undefined) {
                        if (res.data.message !== 'no_session' ) {
                            func.addLog('topnavi', 'chkSession', res)
                        }
                    }
                }

            } catch (error) {
                validSession.value = false
                func.addLog('topnavi', 'chkSession - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                authFunc.clearStorage()
                console.info('topnavi err', error)
            }
        }

        const resendActivation = () => {
            const p = {
                username: resendEmail.value,
                refId: refIdResend.value,
                captcha: captchaResend.value,
                frontendUrl: '/auth/activate'
            } 

            axios.post('/auth/signup/resend', p)
                .then((res) => {
                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Activation email is sent, please wait 5 minutes and check your junk/spam folder."
                        })

                    } else {
                        func.addLog('topnavi', 'resendActivation', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('topnavi', 'resendActivation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const getCaptchaResend = () => {
            axios.get('/reference/captcha/RACTIVATE')
                .then((res) => {
                    if (res.data.status === 1) {
                        captchaImgResend.value = res.data.data.captcha.image
                        refIdResend.value = res.data.data.refId

                    } else {
                        func.addLog('topnavi', 'getCaptchaResend', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('topnavi', 'getCaptchaResend - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getVersion = async () => {
            
            const protocol = window.location.protocol
            const host = window.location.host;
            const url = protocol + '//' + host + '/version.html?ts=' + new Date().getTime()
            // console.info('url ', url)

            fetch(url)
            .then(response => response.text())
            .then(data => {
                
                let arrData = data.split('<body>')
                // console.info('data', data, 'arrData', arrData.length, arrData)
                
                if (arrData.length === 1) {
                    version.value = data.replaceAll('\n', '')

                } else if (arrData.length > 1) {
                    if (arrData[1] !== '') {
                        let newData = arrData[1].replaceAll('\n', '')
                        let arrSubData = newData.split('</body>')

                        if (arrSubData.length > 1) {
                            version.value = arrSubData[0].replaceAll(' ', '')
                        }
                    }
                }

                // console.info('getVersion', version.value)

                if (version.value !== '') {
                    let curVersion = localStorage.getItem('version')
                    // console.info('getVersion','curVer:', curVersion, 'ver:', version.value)

                    if (curVersion === '' || curVersion === null || curVersion === undefined) {
                        localStorage.setItem('version', version.value)

                    } else {
                        if (curVersion !== version.value) {
                            // showRefresh.value = true
                            // console.info('getVersion', '- - detected different version')

                            // localStorage.setItem('version', version.value)
                            router.go()

                            // console.info('getVersion AFTER auto refresh', '- - ', localStorage.getItem('version'), ' vs ', version.value, typeof version.value)
                            
                        }
                    }
                }
            })
            .catch(error => console.error('Error fetching page:', error))
          
        }

        onMounted(async () => {

            if (store.getTheme.value === null && localStorage.getItem('theme') !== undefined && localStorage.getItem('theme') !== null) {
                setTheme(localStorage.getItem('theme'))
            }

            let sId = localStorage.getItem('sessionId')
            if (sId === null || sId === undefined || sId === '') {

            } else {
                await chkSession()
            }

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))

                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            if (getUserIsVerified.value === false || getUserIsVerified.value === 'false') {
                userExpired.value = localStorage.getItem('userExpired')
                userExpired.value = func.convDateTimeFormat(userExpired.value)
                userExpired.value = func.getDateTimeDiffSimple(new Date(), userExpired.value)
            }

            if (func.isEmptyObject(localStorage.getItem('project')) === false) {
                objLSProj.value = func.decodeStrToJsonSimple(localStorage.getItem('project'))

                if (objLSProj.value.id !== undefined && objLSProj.value.id !== null && objLSProj.value.id !== 'null') {
                    projectId.value = objLSProj.value.id
                }
            }

            lnkUploadDoc.value = '/uploadDoc/' + (projectId.value !== null ? projectId.value : '')
            lnkSignatory.value = '/pdf/detail/' + (projectId.value !== null ? projectId.value : '')

            resendEmail.value = store.getUserId.value

            // Trigger close menu when click outside or menu except logo
            window.addEventListener('click', (event) => {
                if (loginMenu.value && event.target.id !== 'SignOnLogo') {
                    if (!loginMenu.value.contains(event.target)) {
                            isMenuOpen.value = false
                    }
                }
            })

            getVersion()

            window.addEventListener('unload', () => {
                // console.info('onMounted: unload / refresh', localStorage.getItem('version'), version.value)
                if (localStorage.getItem('version') !== version.value) {
                    // console.info('onMounted: upd version', localStorage.getItem('version'), version.value)
                    localStorage.setItem('version', version.value)
                }
            })
           
        })


        return { 
            alert, closeAlert, func, route, router, store, isSignOn, sessionId, userId, validSession, 
            lnkUploadDoc, lnkSignatory, theme, arrMenu, email, displayName, isMenuOpen,loginMenu,
            getDisplayName, getAvatar, getUserId, logout, setLang, 
            setTheme, getTheme, getUserIsVerified, getUserExpired, getUserStatus, getPackage, getStructure, userStructure, resendActivation, userExpired,
            getCaptchaResend, resendActivation, captchaImgResend, refIdResend, captchaResend, resendEmail, showRefresh, version
        }
    }
}
</script>

<style>
#lblNavCenter {
    width: 100%;
    font-size: 10px;
    font-weight: 500;
    margin-left: 5px;
    color: var(--form-control-text);
}

#lblNavDesc {
    font-size: 10px;
    font-weight: 500;
    margin-left: 5px;
    color: var(--form-control-text);
}

#lblNavSubDesc {
    display: none;
}

@media (min-width: 576px) { 
    #lblNavCenter {
        color: var(--form-control-text);
        font-size: 14px;
        margin-left: 107px;
    }

    #lblNavDesc {
        font-size: 14px;
        font-weight: 500;
        color: var(--form-control-text);
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #lblNavSubDesc {
        display: block;
        font-size: 10px;
        color: var(--form-control-text);
        padding-top: 0px;
        padding-bottom: 0px;
    }
 }


.step-number {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 25px;
    height: 25px;
    padding: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 9999px;
    color: #FFFFFF;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 11px;
    background-color: var(--bs-primary);
}

.dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
}
.dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
}
.dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
}

#loginMenu {
    width: 100%; 
    opacity: 0.95; 
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    font-size: var(--bs-dropdown-font-size);
    text-align: left;
    left: 0px;
    margin-top: 3px;
    border-top: 1px solid var(--accent-color);
}

/* Trigger menu when mouseover logo */
/* @media all and (min-width: 992px) {

    .navbar .navbar-brand .dropdown-menu {
        display: none;
    }

    .navbar .navbar-brand:hover {}
        
    .navbar .navbar-brand:hover .dropdown-menu {
        display: block;
    }

    // Right Menu
    #topRightMenu .nav-item .dropdown-menu {
        display: none;
    }

    #topRightMenu .nav-item:hover {}

    #topRightMenu .nav-item:hover .dropdown-menu {
        display: block;
    }
} */

a:link {
    color: #6C757D;
}

a:visited {
    color: #6C757D;
}

a:hover {
    color: #53BDEB;
}

a:active {
    color: #53BDEB;
}

.selectedMenu {
    color: #53BDEB;
}

.selectedMenu a:link, .selectedMenu a:visited, .selectedMenu a:hover, .selectedMenu a:active {
    color: #53BDEB;
}

.menuBox {
    width: 100px;
    height: 80px;
    text-align: center;
    padding-top: 8px;
    margin-right: 2px;
}

.menu-icon {
    font-size: 32px;
}

.menu-text {
    font-size: 12px;
}

/* .navbar-nav > .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
    right: 0;
 } */

/* .lnkLogo:hover > .dropdown-menu {
    display: block;
    margin-top: 0; 
    right: 0;
    background-color: green;
}
.lnkLogo:hover {
    background-color: yellow;
} */

 /* desktop view */
/* @media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{   }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}	 */
/* desktop view .end */

/* .dropdown:hover .dropdown-menu {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-menu.show {
  margin-top: -1vh;
  width: 40%;
  margin-left: 38vw;
}

.dropdown-item {
  width: 25% !important;
} */
</style>